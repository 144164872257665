<template>
  <div>
    <b-form method="post" class="register">
      <div class="row mb-4">
        <div class="col-12">
          <b-alert show
            >رقم هاتفك الذي سجلت به هو:
            <strong>{{ phoneNumber }}</strong></b-alert
          >
        </div>
      </div>
      <div ref="name" class="row mb-4 mx-auto">
        <label for="username">
          اسم المستخدم
          <i class="hi-user"></i>
          <b-form-input
            v-model="name"
            :class="{ 'has-error': $v.name.$error }"
            placeholder="ادخل اسمك هنا"
          />
        </label>
        <span
          v-if="submitted && !$v.name.required"
          class="invalid-feedback d-block"
          >من فضلك أدخل الإسم بشكل صحيح</span
        >
      </div>
      <div ref="userType" class="mb-4 mx-auto">
        <label>نوع الحساب</label>
        <multiselect
          id="account-type"
          v-model="userType"
          :show-labels="false"
          :class="{ 'has-error': $v.userType.$error }"
          placeholder="اختر نوع الحساب"
          :options="userTypes"
          track-by="_id"
          value-field="code"
          text-field="nameAr"
          label="name"
        >
          <template slot="noOptions">لا توجد عناصر</template>
        </multiselect>
        <span
          v-if="submitted && !$v.userType.required"
          class="invalid-feedback d-block"
          >من فضلك اختر نوع الحساب</span
        >
      </div>

      <div v-if="!propertySeeker">
        <div ref="clientType" class="mb-4 mx-auto">
          <label>نوع العميل</label>
          <multiselect
            id="account-type"
            v-model="clientType"
            :show-labels="false"
            :class="{ 'has-error': $v.clientType.$error }"
            placeholder="اختر نوع العميل"
            :options="clientTypes"
            track-by="_id"
            value-field="code"
            text-field="nameAr"
            label="name"
          >
            <template slot="noOptions">لا توجد عناصر</template>
          </multiselect>
          <span
            v-if="submitted && !$v.clientType.required"
            class="invalid-feedback d-block"
            >من فضلك اختر نوع العميل</span
          >
        </div>

        <div v-if="isIndividual">
          <div ref="idType" class="mb-4 mx-auto">
            <label>الصفة</label>
            <multiselect
              v-model="idType"
              :show-labels="false"
              :class="{ 'has-error': $v.idType.$error }"
              placeholder="اختر الصفة"
              :options="idTypes"
              track-by="code"
              value-field="code"
              text-field="name"
              label="name"
            ></multiselect>
            <span
              v-if="submitted && !$v.idType.required"
              class="invalid-feedback d-block">
              من فضلك اختر الصفه
            </span>
          </div>

          <div ref="idNumber" class="row mb-4 mx-auto">
            <p>
              <label for="idNumber">
                رقم الهوية
                <i class="hi-user"></i>
                <b-form-input
                  v-model="idNumber"
                  :class="{ 'has-error': $v.idNumber.$error }"
                  placeholder="ادخل رقم الهويه"
                  :customValidate="/^[0-9]*$/"
                />
              </label>
              <span
                v-if="submitted && !$v.idNumber.required"
                class="invalid-feedback d-block"
                >من فضلك أدخل رقم الهوية</span
              >
              <span
                v-else-if="submitted && !$v.idNumber.numeric"
                class="invalid-feedback d-block"
                >رقم الهوية يجب أن يحتوي على أرقام فقط</span
              >
            </p>
          </div>
        </div>

        <div
          v-else-if="clientType && !isIndividual"
          ref="licenseNumber"
          class="row mb-4 mx-auto"
        >
          <p>
            <label for="licenseNumber">
              السجل التجاري
              <i class="hi-user"></i>
              <b-form-input
                v-model="licenseNumber"
                :class="{ 'has-error': $v.licenseNumber.$error }"
                placeholder="ادخل السجل التجاري"
                :customValidate="/^[0-9]*$/"
              />
            </label>
            <span
              v-if="submitted && !$v.licenseNumber.required"
              class="invalid-feedback d-block"
              >من فضلك أدخل السجل التجاري</span
            >
            <span
              v-else-if="submitted && !$v.licenseNumber.numeric"
              class="invalid-feedback d-block"
            >
              السجل التجاري يجب ان يحتوي على أرقام فقط
            </span>
          </p>
        </div>

        <div ref="registrationNumber" class="row mb-4 mx-auto">
          <p>
            <label for="registrationNumber">
              رقم الترخيص (رقم المعلن)
              <i class="hi-user"></i>
              <b-form-input
                v-model="registrationNumber"
                :class="{ 'has-error': $v.registrationNumber.$error }"
                placeholder="ادخل رقم الترخيص (رقم المعلن)"
                :customValidate="/^[0-9]*$/"
              />
            </label>
            <span
              v-if="submitted && !$v.registrationNumber.required"
              class="invalid-feedback d-block"
              >من فضلك أدخل رقم الترخيص (رقم المعلن)</span
            >
            <span
              v-else-if="submitted && !$v.registrationNumber.numeric"
              class="invalid-feedback d-block"
              >رقم الترخيص (رقم المعلن) يجب أن يحتوي على أرقام فقط</span
            >
          </p>
        </div>
      </div>

      <div v-if="showLocation" ref="company" class="row mb-4 mx-auto">
        <p>
          <label for="username">
            اسم المكتب او الشركه
            <i class="hi-user"></i>
            <b-form-input
              v-model="company"
              :class="{ 'has-error': $v.company.$error }"
              placeholder="ادخل المكتب او الشركه هنا"
            />
          </label>
          <span
            v-if="submitted && !$v.company.required"
            class="invalid-feedback d-block"
            >من فضلك أدخل الإسم بشكل صحيح</span
          >
        </p>
      </div>
      <div ref="email" class="row mb-4 mx-auto">
        <p>
          <label for="email2">
            البريد الالكتروني
            <i class="hi-envelope"></i>
            <b-form-input
              v-model="email"
              :class="{ 'has-error': $v.email.$error }"
              placeholder="ادخل بريدك الإلكتروني"
            />
          </label>
          <span
            v-if="
              submitted &&
              !$v.email.email &&
              !$v.email.mustBeCool &&
              !$v.email.required
            "
            class="invalid-feedback d-block"
            >من فضلك أدخل بريدك الكتروني صحيح</span
          >
        </p>
      </div>

      <div v-if="showLocation" ref="location" class="mb-4">
        <div>
          <div class="gmap-search-bar">
            <p class="mb-2 mt-3">
              ابدأ بكتابة موقع العقار ليتم اقتراح العنوان المناسب
            </p>

            <label class="d-flex justify-center autosuggest__input-wrap">
              <gmap-autocomplete
                class="autosuggest__input form-control"
                :value="address"
                :class="{ 'has-error': address === '' }"
                @place_changed="setPlace"
                :options="autocompleteOptions"
              >
              </gmap-autocomplete>
              <i class="hi-map-pin icon mt-2"></i>
            </label>

            <p v-if="address === ''" class="invalid-feedback d-block">
              من فضلك حدد موقع المكتب او الشركه العقاريه
            </p>
          </div>
          <gmap-map
            class="mt-5"
            ref="mapRef"
            :center="center"
            :zoom="14"
            style="width: 100%; height: 400px"
            :options="{
              streetViewControl: false,
              mapTypeControl: false
            }"
          >
            <gmap-marker
              ref="marker"
              :position="markerPosition"
              :clickable="true"
              :draggable="true"
              @dragend="getFormattedAddress($event.latLng)"
              @click="center = m.position"
            />
          </gmap-map>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import { mapActions, mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { userService } from '@/services'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {
  email,
  required,
  minLength,
  requiredIf,
  numeric
} from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect
  },
  props: ['phoneNumber'],
  data() {
    return {
      licenseNumber: '',
      name: '',
      email: '',
      userType: null,
      clientTypes: [
        {
          _id: 1,
          name: 'فرد',
          code: 'individual'
        },
        {
          _id: 2,
          name: 'شركه',
          code: 'company'
        }
      ],
      clientType: null,
      // password: "",
      terms: false,
      submitted: false,
      // passwordFieldType: "password",
      location: null,
      address: '',
      agentName: '',
      company: '',
      markerPosition: { lat: 24.448378, lng: 39.586848 },
      center: { lat: 24.448378, lng: 39.586848 },
      currentPlace: null, // null
      hasError: false,
      autocompleteOptions: {
        componentRestrictions: {
          country: ['sa']
        }
      },
      idType: null,
      idTypes: [
        { name: 'مواطن', code: 'citizen' },
        { name: 'مقيم', code: 'resident' }
      ],
      fields: [
        'name',
        'userType',
        'clientType',
        'idType',
        'idNumber',
        'licenseNumber',
        'registrationNumber',
        'company',
        'email',
        'location'
      ],
      registrationNumber: '',
      idNumber: ''
    }
  },
  mounted() {
    // Prevent a link inside a label from triggering a checkbox
    var termLink = document.getElementsByClassName('termLink')
    var termClickHandler = function (event) {
      event.stopPropagation()
      event.preventDefault()
      return false
    }
    for (var i = 0; i < termLink.length; i++) {
      termLink[i].addEventListener('click', termClickHandler)
      termLink[i].addEventListener('touchstart', termClickHandler)
    }
  },
  computed: {
    errorMsg() {
      if (this.name.length < 3) return 'لا يمكن ان يكون الاسم اقل من 3 أحرف'
      else if (!this.userType) return 'من فضلك اختر نوع الحساب'
      else if (!this.propertySeeker&&!this.clientType) return 'من فضلك اختر نوع العميل'
      else if (this.isIndividual&&!this.idType) return 'من فضلك اختر الصفه'
      else if (this.isIndividual&&!this.idNumber) return 'من فضلك ادخل رقم الهويه'
      else if (!this.isIndividual&&!this.licenseNumber) return 'من فضلك أدخل السجل التجاري'
      else if (!this.propertySeeker && this.registrationNumber.length < 6)
        return 'لا يمكن ان يكون رقم الترخيص (رقم المعلن) اقل من 6 أرقام'
      else if (this.showLocation && this.company.length < 3)
        return 'لا يمكن ان يكون اسم الشركه اقل من 3 أحرف'
      else if (this.showLocation && !this.address)
          return 'من فضلك حدد موقع المكتب او  الشركه  العقاريه'
      return 'من فضلك تأكد من جميع الحقول'
    },
    google: gmapApi,
    ...mapState('accountState', ['configs']),
    ...mapState('userState', ['spinnerLoading']),
    user() {
      let user = {
        name: this.name,
        //password: this.password,
        phoneNumber: this.phoneNumber,
        type: this.userType.code,
        clientType: this.clientType?.code,
        location: this.location || null,
        address: this.address,
        lat: this.location?.lat,
        lng: this.location?.lng
      }
      if (this.showLocation) user.company = this.company
      if (this.email) user.email = this.email
      if (!this.propertySeeker) {
        user.registrationNumber = this.registrationNumber
        if (this.isIndividual) {
          user.idType = this.idType?.code
          user.idNumber = this.idNumber
        } else user.licenseNumber = this.licenseNumber
      }

      return user
    },
    userTypes() {
      return this.configs.userTypes.map((item) => ({
        _id: item._id,
        name: item.nameAr,
        code: item.code
      }))
    },
    showLocation() {
      return (
        this.userType?.code === 'office' || this.userType?.code === 'company'
      )
    },
    propertySeeker() {
      return this.userType?.code === 'seeker'
    },
    isIndividual() {
      return this.clientType?.code === 'individual'
    }
  },
  watch: {
    userType(val) {
      if (val?.code === 'seeker') {
        this.clientType = null
        this.idType = null
      }
    }
  },

  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    userType: {
      required
    },
    clientType: {
      required: requiredIf(function () {
        return !this.propertySeeker
      })
    },
    idType: {
      required: requiredIf(function () {
        return this.isIndividual
      })
    },
    idNumber: {
      numeric: numeric,
      required: requiredIf(function () {
        return this.isIndividual
      })
    },
    licenseNumber: {
      numeric: numeric,
      required: requiredIf(function () {
        return this.clientType && !this.isIndividual
      })
    },
    registrationNumber: {
      numeric: numeric,
      minLength: minLength(6),
      required: requiredIf(function () {
        return !this.propertySeeker
      })
    },
    company: {
      minLength: minLength(3),
      required: requiredIf(function () {
        return this.showLocation
      })
    },
    email: {
      email
    },
    location: {
      required: requiredIf(function () {
        return this.showLocation
      })
    },

    form: [
      'name',
      'userType',
      'clientType',
      'idType',
      'idNumber',
      'licenseNumber',
      'registrationNumber',
      'company',
      'email',
      'location'
    ]
  },

  methods: {
    ...mapActions('accountState', ['register', 'createUser']),
    ...mapMutations('accountState', [
      'registerSuccess',
      'loginSuccess',
      'registerFailure'
    ]),
    setPlace(location) {
      if (location) {
        this.address = location.formatted_address
        this.currentPlace = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address
        }
        this.markerPosition = this.currentPlace
        this.center = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address
        }
        this.location = this.center
        this.hasError = false
      }
    },

    getFormattedAddress(location) {
      // Convert the latlang to formatted address
      var geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ latLng: location }, (results) => {
        this.location = {
          lat: location.lat(),
          lng: location.lng()
        }
        this.currentPlace = {
          lat: location.lat(),
          lng: location.lng(),
          address: results[0].formatted_address
        }
        this.address = results[0].formatted_address
      })
    },
    ...mapMutations('userState', ['setSpinnerLoading']),
    // switchVisibility() {
    //     this.passwordFieldType =
    //         this.passwordFieldType === "password" ? "text" : "password";
    // },
    validate() {
      this.submitted = true
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)

      if (!isValid) {
        const formValidationObj = this.$v.form
        this.fields.every((field) => {
          if (formValidationObj[field].$invalid) {
            this.scrollToElement(this.$refs[field])
            return false
          }
          return true
        })
          this.$toasted.show(this.errorMsg, {
            duration: 2000,
            type: 'error',
            icon: 'fa-times'
          })
      } else {
        this.reg()
      }
      return isValid
    },
    reg() {
      this.setSpinnerLoading(true)
      const token = JSON.parse(localStorage.getItem('tokenData'))
      userService
        .createUser({ ...this.user, accountId: token._id })
        .then((user) => {
          if (user.data.user) {
            const userToken = {
              active: 1,
              accountId: token._id,
              token: token.token,
              type: user.data.user.type || null,
              company: user.data.user.company || null,
              licenseNumber: user.data.user.licenseNumber || null,
              address: user.data.user.address,
              email: user.data.user.email,
              location: user.data.user.location || null,
              name: user.data.user.name,
              phoneNumber: token.phoneNumber,
              roles: user.data.user.roles,
              tags: user.data.user.tags,
              _id: user.data.user._id,
              clientType: user.data.user.clientType,
              idType: user.data.user.idType,
              registrationNumber: user.data.user.registrationNumber,
              idNumber: user.data.user.idNumber
            }
            localStorage.setItem('tokenData', JSON.stringify(userToken))
            localStorage.setItem('logged_in', 'true')
            this.registerSuccess(userToken)

            this.$toasted.show('تم تسجيل حسابك بنجاح، شكراً لك', {
              duration: 2000,
              type: 'success',
              icon: 'hi-check'
            })

            // Auto login after registration
            this.loginSuccess(userToken)
            this.$router.push({ name: 'home' })
            this.$toasted.show(
              `أهلا بك ${user.data.user.name} تم تسجيل حسابك بنجاح! `,
              { duration: 2000, type: 'success', icon: 'hi-check' }
            )
          }
        })
        .catch(() => {
          this.registerFailure()
          this.$toasted.show('خطأ في تسجيل حساب جديد يرجى المحاولة مجددا', {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        })
        .finally(() => {
          this.setSpinnerLoading(false)
        })
    },
    scrollToElement(element) {
      const headerOffset = 100
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }
}
</script>
