<template>
  <div class="height-mobile-fix">
    <!-- Titlebar -->
    <div class="parallax-container">
      <div class="parallax titlebar">
        <div id="titlebar">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h2>اشتراك / تسجيل الدخول</h2>
                <span>اشترك معنا و استفد من خدمات الموقع</span>
                <!-- Breadcrumbs -->
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <router-link to="/">الرئيسية</router-link>
                    </li>
                    <li>اشتراك / تسجيل الدخول</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
        <img :src="titleBg" />
      </parallax>
    </div>

    <!-- Container -->
    <div class="container">
      <div class="row">
        <div
          id="login-register-tabs"
          class="col-md-8 col-sm-12 col-md-offset-2 col-sm-offset-0"
        >
          <!--Tab -->

          <div class="my-account style-1 margin-top-30 margin-bottom-30">
            <b-tabs
              nav-class="tabs-nav"
              content-class="tabs-container"
              align="center"
            >
              <!-- Login -->
              <b-tab title="تسجيل دخول" active>
                <b-form ref="loginform" method="post" class="login">
                  <div class="form-row form-row-wide">
                    <p>
                      <label for="phone-no"
                        >رقم الجوال
                        <vue-tel-input
                          v-model="phoneNumber"
                          @keydown.native.space.prevent
                          id="login-phoneNumber"
                          placeholder="ادخل رقم الجوال"
                          :only-countries="['SA']"
                          default-country="SA"
                          :class="{
                            'has-error': !isValidPhone && submitted,
                            'is-valid': isValidPhone
                          }"
                          :required="true"
                          :customValidate="/^[0-9]*$/"
                          @validate="onValidatePhoneNumber"
                        >
                        </vue-tel-input>
                      </label>
                    </p>
                  </div>
                  <div class="form-row form-row-wide">
                    <p>
                      <label for="password"
                        >كلمة المرور
                        <i class="hi-lock"></i>
                        <b-form-input
                          @keydown.space="(event) => event.preventDefault()"
                          id="password login-password"
                          ref="password"
                          v-model="password"
                          class="input-text"
                          :class="{ 'has-error': $v.password.$error }"
                          :type="passwordFieldType"
                          placeholder="ادخل كلمة المرور"
                          @blur="$v.password.$touch()"
                        />
                        <i
                          class="show-hide-password"
                          :class="{
                            'hi-eye': passwordFieldType === 'password',
                            'hi-eye-crossed': passwordFieldType === 'text'
                          }"
                          @click="switchVisibility"
                        ></i>
                      </label>
                    </p>
                  </div>

                  <div class="form-row form-row-wide">
                    <p>
                      <b-button
                        type="submit"
                        variant="primary"
                        class="button margin-bottom-0"
                        :disabled="isloggingIn"
                        @click.prevent="validateLoginForm"
                      >
                        <span> تسجيل الدخول</span>
                        <b-spinner
                          v-show="isloggingIn"
                          small
                          class="mr-2"
                        ></b-spinner>
                      </b-button>
                    </p>

                    <p class="flexable flexable--row flexable--s-between">
                      <label
                        for="rememberme"
                        class="rememberme margin-top-0 margin-bottom-0"
                        ><input
                          id="rememberme"
                          name="rememberme"
                          type="checkbox"
                          value="forever"
                        />تذكر بياناتي</label
                      >
                      <router-link to="/auth/forget-password"
                        >نسيت كلمة المرور؟
                      </router-link>
                    </p>
                  </div>
                </b-form>
              </b-tab>

              <!-- Register -->
              <b-tab title="تسجيل حساب جديد">
                <form-wizard
                  id="register-wizard"
                  ref="regWizard"
                  shape="circle"
                  title
                  subtitle
                  next-button-text="التالي"
                  back-button-text="رجوع"
                  finish-button-text="تسجيل"
                  step-size="xs"
                  color="#3cab74"
                  error-color="#e74c3c"
                >
                  <!-- Step #1 ----------------------------------------------------->
                  <tab-content :before-change="() => validateStep('step1')">
                    <register-step1
                      ref="step1"
                      @on-validate="onCheckPhoneNumber"
                    >
                    </register-step1>
                  </tab-content>

                  <!-- Step #2 ----------------------------------------------------->
                  <tab-content :before-change="() => validateStep('step2')">
                    <div id="step-2">
                      <div class="step-title mb-5">
                        <h4 class="submit-section-header">
                          التحقق من رقم الجوال
                        </h4>
                        <p>
                          فضلا قم بإدخال رمز التحقيق المكون من ٤ أرقام المرسل
                          إلى جوالك رقم
                          <strong>{{ finalModel.phoneNumber }}</strong>
                        </p>
                      </div>
                      <register-step2
                        ref="step2"
                        :password="finalModel.password"
                        :phone-number="finalModel.phoneNumber"
                        @on-validate="onConfirmPhoneNumber"
                      ></register-step2>
                    </div>
                  </tab-content>

                  <!-- Step #3 ----------------------------------------------------->
                  <tab-content :before-change="() => validateStep('step3')">
                    <register-step3
                      ref="step3"
                      :phone-number="finalModel.phoneNumber"
                      @on-validate="collectData"
                    ></register-step3>
                  </tab-content>

                  <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-right">
                      <b-button
                        v-if="props.activeTabIndex > 0"
                        variant="primary"
                        @click.prevent="props.prevTab()"
                        >الخطوة السابقة
                      </b-button>
                    </div>
                    <div class="wizard-footer-left">
                      <b-button
                        v-if="!props.isLastStep"
                        variant="primary"
                        class="wizard-footer-right"
                        @click.prevent="props.nextTab()"
                      >
                        <span>الخطوة التالية</span>
                        <b-spinner
                          v-show="spinnerLoading"
                          small
                          class="mr-2"
                        ></b-spinner>
                      </b-button>

                      <b-button
                        v-else
                        variant="primary"
                        class="wizard-footer-right finish-button"
                        @click.prevent="validateStep('step3')"
                      >
                        <span>تسجيل حساب جديد</span>
                        <b-spinner
                          v-show="spinnerLoading"
                          small
                          class="mr-2"
                        ></b-spinner>
                      </b-button>
                    </div>
                  </template>
                </form-wizard>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top-55"></div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { FormMixin } from '@/mixins/form.mixin'
import Parallax from 'vue-parallaxy'
//Wizard
import { userService } from '@/services' //Modals
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import RegisterStep1 from '@/components/login-register/RegisterStep1.vue'
import RegisterStep2 from '@/components/login-register/RegisterStep2.vue'
import RegisterStep3 from '@/components/login-register/RegisterStep3.vue'

import { VueTelInput } from 'vue-tel-input'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  layout: 'main',
  components: {
    Parallax,
    FormWizard,
    TabContent,
    RegisterStep1,
    RegisterStep2,
    RegisterStep3,
    VueTelInput
  },
  mixins: [FormMixin],
  data() {
    return {
      titleBg: require('../../assets/images/home-parallax-2.jpg'),
      finalModel: {},
      phoneNumber: '',
      phoneData: {},
      password: '',
      isloggingIn: false,
      submitted: false,
      passwordFieldType: 'password'
    }
  },

  mounted() {
    let isLoggedInFromStorage = localStorage.getItem('logged_in')

    if (isLoggedInFromStorage === 'true') {
      this.$router.push(this.$route.query.redirect || '/')
    }
  },

  computed: {
    ...mapState('accountState', ['status']),
    ...mapState('userState', ['spinnerLoading']),
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    }
  },

  validations: {
    phoneNumber: {
      required
    },
    password: {
      required
    },
    form: ['phoneNumber', 'password']
  },

  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    validateStep(name) {
      var refToValidate = this.$refs[name]
      return refToValidate.validate()
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },

    ...mapActions('accountState', [
      'login',
      'register',
      'checkPhoneNumber',
      'confirmPhoneNumber'
    ]),
    ...mapMutations('accountState', [
      'otpConfirmed',
      'loginSuccess',
      'loginFailure'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),

    // Register form ---------------------------------
    onCheckPhoneNumber(phoneNumber, password) {
      console.log(password)
      let phone = { phoneNumber: phoneNumber.number.e164.replace(/\+/g, '') }
      this.collectData(phone, true)
      this.collectData({ password }, true)
      this.setSpinnerLoading(true)
      userService.checkPhoneNumber(phone).then(
        (res) => {
          this.collectData(
            { confirmationCode: res.data.confirmationCode },
            true
          )

          this.$toasted.show('تم إرسال الرقم التأكيدي لهاتفك بنجاح!', {
            duration: 2000,
            type: 'success',
            icon: 'hi-check'
          })
          // Go to next step
          this.$refs.regWizard.changeTab(0, 1)
          this.setSpinnerLoading(false)
          //remove this line on production
          //this.$refs.step2.validate();
        },
        () => {
          this.setSpinnerLoading(false)
          this.$toasted.show('رقم الهاتف مُسجل لدينا من قبل، ادخل رقم جديد!', {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        }
      )
    },

    onConfirmPhoneNumber(data) {
      this.collectData(data, true)
      this.setSpinnerLoading(true)
      const dataToSend = {
        confirmationCode: data.confirmationCode,
        phoneNumber: data.phoneNumber,
        showMsg: data.showMsg
      }
      userService
        .confirmPhoneNumber(dataToSend)
        .then((response) => {
          if (response.data.success) {
            this.$toasted.show('تم التحقق من رقم هاتفك', {
              duration: 2000,
              type: 'success',
              icon: 'hi-check'
            })
            this.register({
              phoneNumber: data.phoneNumber,
              password: data.password
            }).then((res) => {
              if (res.data.user) {
                localStorage.setItem('tokenData', JSON.stringify(res.data.user))
              }
            })
            //register the number

            // this.otpConfirmed(true);
            this.$refs.regWizard.changeTab(1, 2)
          } else {
            this.$toasted.show('رقم التحقيق غير صحيح', {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          }
        })
        .catch((err) => {
          if (err.response?.data?.message === 'الرقم المدخل خاطئ') {
            this.$toasted.show('رقم التحقيق الذي أدخلته غير صحيح', {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          } else {
            this.$toasted.show(
              'حدث خطأ أثناء تأكيد رمز التحقيق الرجاء المحاوله مجددا',
              {
                duration: 2000,
                type: 'error',
                icon: 'hi-close'
              }
            )
          }
        })
        .finally(() => {
          this.setSpinnerLoading(false)
        })
    },

    // Login Form -------------------------------------
    validateLoginForm() {
      this.$v.form.$touch()
      this.setSpinnerLoading(true)

      if (this.$v.form.$invalid) {
        this.$toasted.show('من فضلك تأكد من البيانات', {
          duration: 3000,
          type: 'error',
          icon: 'hi-close'
        })
        this.setSpinnerLoading(false)
      } else {
        this.handleLoginFormSubmit()
      }
    },

    handleLoginFormSubmit() {
      this.isloggingIn = true
      this.submitted = true
      let phoneNumber = this.phoneData.number.e164.replace(/\+/g, '')
      let password = this.password
      if (phoneNumber && password) {
        userService.login(phoneNumber, password).then(
          (res) => {
            this.isloggingIn = false
            if (res.data.user.token) {
              userService
                .getUserByAccountId(res.data.user._id, res.data.user.token)
                .then((response) => {
                  if (!response.data.user) {
                    return this.$toasted.show('بيانات التسجيل غير مكتملة', {
                      duration: 2000,
                      type: 'danger',
                      icon: 'hi-check'
                    })
                  }
                  const userData = {
                    active: res.data.user.active,
                    accountId: res.data.user._id,
                    token: res.data.user.token,
                    type: response.data.user.type || null,
                    company: response.data.user.company || null,
                    address: response.data.user.address,
                    email: response.data.user.email,
                    location: response.data.user.location,
                    name: response.data.user.name,
                    environments: response.data.user.environments || null,
                    phoneNumber: res.data.user.phoneNumber,
                    roles: response.data.user.roles,
                    tags: response.data.user.tags,
                    licenseNumber: response.data.user.licenseNumber || null,
                    registrationNumber:
                      response.data.user.registrationNumber || null,
                    _id: response.data.user._id,
                    clientType: response.data.user.clientType,
                    idType: response.data.user.idType,
                    idNumber: response.data.user.idNumber
                  }

                  localStorage.setItem('tokenData', JSON.stringify(userData))
                  localStorage.setItem('logged_in', 'true')
                  this.loginSuccess(userData)
                  //this.setSpinnerLoading(false);
                  this.$toasted.show(
                    'أهلا بك ' +
                      response.data.user.name +
                      ' ، تم تسجيل دخولك بنجاح!',
                    { duration: 2000, type: 'success', icon: 'hi-check' }
                  )
                })
            }
            this.$router.push(this.$route.query.redirectFrom || '/')
          },
          (error) => {
            this.isloggingIn = false
            this.loginFailure(error)
            this.setSpinnerLoading(false)
            this.$toasted.show('من فضلك تأكد من رقم جوالك وكلمة المرور', {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          }
        )
      }
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
      if (this.isValidPhone) this.isloggingIn = false
    },
    isNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-form {
  width: 400px;
  padding: 25px 15px;
}

div.Masthead:after {
  background-color: rgb(61, 186, 126);
  opacity: 0.9;
}

label {
  font-size: 16px;
}
input[type='checkbox'] {
  filter: hue-rotate(280deg);
  margin: 10px;
  cursor: pointer;
}
.vti__dropdown {
  pointer-events: none;
}
.wizard-tab-content,
.vue-form-wizard .wizard-card-footer {
  padding: 0 !important;
}
</style>
